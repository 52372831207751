<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" sm="0" md="1" lg="1" ></v-col>
            <v-col cols="12" sm="12" md="11" lg="11" xl="10">
                <h2>Actionneurs</h2>
                <div class="actionneurs-liste" ref="scrollContainer">
                    <div class="item-actionneur">
                        <div class="info-actionneur">
                            <img src="../images/Roue3D.png" alt="">
                            <div></div>
                            <h3>Roue d'exercice</h3>
                            <p class="dimension">( 227 x 144 x 151 mm )</p>
                            <p>Activité et Comportement</p>
                        </div>
                        <div class="detail-actionneur">
                            <div style="width: 100%;">
                                <h3>Roue d'exercice</h3>
                            </div>
                            <p>Mesure l'activité physique et les données physiologiques des animaux l’utilisant. Le vitesse de rotation peut être programmée</p>
                            <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                        </div>
                    </div>
                    <div class="item-actionneur">
                        <div class="info-actionneur">
                            <img src="../images/AirPuff3D.png" alt="">
                            <div></div>
                            <h3>Air Puff</h3>
                            <p class="dimension">( 96 x 80 x 110 mm )</p>
                            <p>Stimulation par jet d’air</p>
                        </div>
                        <div class="detail-actionneur">
                            <div style="width: 100%;">
                                <h3>Air Puff</h3>
                            </div>
                            <p>Utilisé pour générer des stimulus non-invasifs. Il peut être déclenché selon divers paramètres (ID, température etc..)</p>
                            <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                        </div>
                    </div>
                    <div class="item-actionneur">
                        <div class="info-actionneur">
                            <img src="../images/Tunnel3D.png" alt="">
                            <div></div>
                            <h3>Porte</h3>
                            <p class="dimension">( 52 x 64 x 111 mm )</p>
                            <p>Ouverture - Fermeture</p>
                        </div>
                        <div class="detail-actionneur">
                            <div style="width: 100%;">
                                <h3>Porte</h3>
                            </div>
                            <p>Permet de gérer l’accès à différentes zones de la cage en fonction des paramètres définis par le chercheur</p>
                            <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                        </div>
                    </div>
                    <div class="item-actionneur">
                        <div class="info-actionneur">
                            <img src="../images/Mangeoir3D.png" alt="">
                            <div></div>
                            <h3>Distributeur</h3>
                            <p class="dimension">( 227 x 144 mm )</p>
                            <p>Ouverture - Fermeture</p>
                        </div>
                        <div class="detail-actionneur">
                            <div style="width: 100%;">
                                <h3>Distributeur</h3>
                            </div>
                            <p>Permet de contrôler l'accès à la nourriture en fonction de l’identification des animaux ou autres paramètres</p>
                            <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                        </div>
                    </div>
                </div>
                <div class="btn-container" v-if="isScrollable">
                    <button class="scroll-btn left" @click="scrollLeft">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path d="M21.559,12.062 L15.618,17.984 L21.5221,23.944 C22.105,24.533 22.1021,25.482 21.5131,26.065 C21.2211,26.355 20.8391,26.4999987 20.4571,26.4999987 C20.0711,26.4999987 19.6851,26.352 19.3921,26.056 L12.4351,19.034 C11.8531,18.446 11.8551,17.4999987 12.4411,16.916 L19.4411,9.938 C20.0261,9.353 20.9781,9.354 21.5621,9.941 C22.1471,10.528 22.1451,11.478 21.5591,12.062 L21.559,12.062 Z"></path></svg>
                    </button>
                    <button class="scroll-btn right" @click="scrollRight">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path d="M23.5587,16.916 C24.1447,17.4999987 24.1467,18.446 23.5647,19.034 L16.6077,26.056 C16.3147,26.352 15.9287,26.4999987 15.5427,26.4999987 C15.1607,26.4999987 14.7787,26.355 14.4867,26.065 C13.8977,25.482 13.8947,24.533 14.4777,23.944 L20.3818,17.984 L14.4408,12.062 C13.8548,11.478 13.8528,10.5279 14.4378,9.941 C15.0218,9.354 15.9738,9.353 16.5588,9.938 L23.5588,16.916 L23.5587,16.916 Z"></path></svg>
                    </button>
                </div>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" sm="0" md="1" lg="1" ></v-col>
            <v-col cols="12" sm="12" md="11" lg="11" xl="10" class="mt100">
                <h2>Capteurs</h2>
                <div class="actionneurs-liste liste2">
                    <div class="item-actionneur">
                        <div class="info-actionneur">
                            <img src="../images/MTSLife3D.png" alt="">
                            <div></div>
                            <h3>MTS Smart</h3>
                            <p class="dimension">( Ø 52 x 38 mm )</p>
                            <p>Etat Sanitaire, Environnement</p>
                        </div>
                        <div class="detail-actionneur">
                            <div style="width: 100%;">
                                <h3>MTS Smart</h3>
                            </div>
                            <p>Mesure la température, l’hygrométrie, les vibrations, les gazs (CH4, NH3, CO2, H2S) et la lumisoté de la cage</p>
                            <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                        </div>
                    </div>
                    <div class="item-actionneur">
                        <div class="info-actionneur">
                            <img src="../images/BalanceTunnel3D.png" alt="">
                            <div></div>
                            <h3>Tunnel Balance</h3>
                            <p class="dimension">( Ø 70 x 100 mm )</p>
                            <p>Poids et identification</p>
                        </div>
                        <div class="detail-actionneur">
                            <div style="width: 100%;">
                                <h3>Balance-Tunnel</h3>
                            </div>
                            <p>Identifie la souris et mesure le poids de chaque souris qui passe à l’intérieur</p>
                            <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                        </div>
                    </div>
                    <div class="item-actionneur">
                        <div class="info-actionneur">
                            <img src="../images/SphèreDeJeu3D.png" alt="">
                            <div></div>
                            <h3>Sphère de Jeu</h3>
                            <p class="dimension">( Ø = 25mm )</p>
                            <p>Accélération - Comportement</p>
                        </div>
                        <div class="detail-actionneur">
                            <div style="width: 100%;">
                                <h3>Porte</h3>
                            </div>
                            <p>Sphère équipé d’un accéléromètre pour mesurer l’activité des souris</p>
                            <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                        </div>
                    </div>
                    <div class="item-actionneur">
                        <div class="info-actionneur">
                            <img src="../images/SphèreDeJeu3D.png" alt="">
                            <div></div>
                            <h3>Objets</h3>
                            <p class="dimension">( 155 x 100 x 30 mm )</p>
                            <p>Ouverture - Fermeture</p>
                        </div>
                        <div class="detail-actionneur">
                            <div style="width: 100%;">
                                <h3>Mangeoir</h3>
                            </div>
                            <p>Objets munis de puce d'identification</p>
                            <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                        </div>
                    </div>
                </div>
                <div class="btn-container" v-if="isScrollable">
                    <button class="scroll-btn left" @click="scrollLeft2" >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path d="M21.559,12.062 L15.618,17.984 L21.5221,23.944 C22.105,24.533 22.1021,25.482 21.5131,26.065 C21.2211,26.355 20.8391,26.4999987 20.4571,26.4999987 C20.0711,26.4999987 19.6851,26.352 19.3921,26.056 L12.4351,19.034 C11.8531,18.446 11.8551,17.4999987 12.4411,16.916 L19.4411,9.938 C20.0261,9.353 20.9781,9.354 21.5621,9.941 C22.1471,10.528 22.1451,11.478 21.5591,12.062 L21.559,12.062 Z"></path></svg>
                    </button>
                    <button class="scroll-btn right" @click="scrollRight2" >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path d="M23.5587,16.916 C24.1447,17.4999987 24.1467,18.446 23.5647,19.034 L16.6077,26.056 C16.3147,26.352 15.9287,26.4999987 15.5427,26.4999987 C15.1607,26.4999987 14.7787,26.355 14.4867,26.065 C13.8977,25.482 13.8947,24.533 14.4777,23.944 L20.3818,17.984 L14.4408,12.062 C13.8548,11.478 13.8528,10.5279 14.4378,9.941 C15.0218,9.354 15.9738,9.353 16.5588,9.938 L23.5588,16.916 L23.5587,16.916 Z"></path></svg>
                    </button>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
  data() {
    return {
        isScrollable: false

    };
  },
    methods: {
        scrollLeft() {
            const container = this.$el.querySelector('.actionneurs-liste');
            container.scrollBy({
                left: -300, // Distance à défiler
                behavior: 'smooth',
            });
        },
        scrollRight() {
            const container = this.$el.querySelector('.actionneurs-liste');
            container.scrollBy({
                left: 300, // Distance à défiler
                behavior: 'smooth',
            });
        },
        scrollLeft2() {
            const container = this.$el.querySelector('.liste2');
            container.scrollBy({
                left: -300, // Distance à défiler
                behavior: 'smooth',
            });
        },
        scrollRight2() {
            const container = this.$el.querySelector('.liste2');
            container.scrollBy({
                left: 300, // Distance à défiler
                behavior: 'smooth',
            });
        },
        checkIfScrollable() {
            const scrollContainer = this.$refs.scrollContainer;
            this.isScrollable = scrollContainer.scrollWidth > scrollContainer.clientWidth;
        },
        scrollLeft() {
            this.$refs.scrollContainer.scrollBy({ left: -200, behavior: 'smooth' });
        },
        scrollRight() {
            this.$refs.scrollContainer.scrollBy({ left: 200, behavior: 'smooth' });
        }
    },
    mounted() {
        this.checkIfScrollable();
        window.addEventListener('resize', this.checkIfScrollable);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkIfScrollable);
    }
};

</script>

<style scoped>
.actionneurs-liste{
    display: flex;
    margin-top: 60px;
    overflow-x: hidden; /* Ajoute le défilement horizontal */
    padding-bottom: 20px;
    gap: 50px;
}

.item-actionneur{
    background-color: var(--white);
    width: 300px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    min-height: 500px; /* Ajoute une hauteur minimale */
    overflow: hidden; /* Empêche le contenu de déborder */
    flex-shrink: 0; /* Empêche la réduction de la taille des éléments */
}

.info-actionneur,
.detail-actionneur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
}

.detail-actionneur{
    padding: 15px;
}

.info-actionneur {
    opacity: 1;
    visibility: visible;
}

.item-actionneur:hover .info-actionneur {
    opacity: 0;
    visibility: hidden;
}

.item-actionneur:hover .detail-actionneur {
    opacity: 1;
    visibility: visible;
}

.item-actionneur div{
    text-align: center;
}

.item-actionneur div img{
    width: 80%;
    margin-top: 35px;
    margin-bottom: 60px;
}

.info-actionneur div{
    width: 80%;
    background-color: black;
    height: 1px;
    margin: 0 auto;
}

.info-actionneur h3{
    margin-top: 20px;
}

.info-actionneur h4{
    margin-bottom: 25px;
}

.detail-actionneur h3,
.detail-actionneur p{
    text-align: left;
    margin-left: 0;
}

.detail-actionneur h3{
    margin-top: 10px;
    margin-bottom: 25px;
}

.detail-actionneur a{
    margin-top: auto;
}

.button-contact {
    background-color: var(--vertfonce);
    padding: 0.2vw 2vw;
    border-radius: 20px;
    color: white;
    border: solid 2px var(--vertfonce);
    font-family: "Roboto", Regular;
    font-size: 16px;
    transition: background 300ms ease-in-out;
    text-decoration: none;
}
.button-contact:hover {
    background-position: 100%;
    color: var(--vertfonce);
    background-color: transparent;
}

.dimension{
    font-family: 'Roboto';
    font-style: italic;
    font-size: 16px;
}

.btn-container{
  position: relative;
  top: 20px;
}

.scroll-btn {
  background-color: var(--grisclair);
  border-radius: 100%;
  width: 36px;
  height: 36px;
  color: black;
  font-size: 30px;
  cursor: pointer;
  z-index: 1;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.scroll-btn:hover {
  opacity: 1;
}

.scroll-btn.left {
  left: -40px; /* Ajustez la position de la flèche gauche */
}

.scroll-btn.right {
  right: -20px; /* Ajustez la position de la flèche droite */
}


@media (max-width: 960px) {
    .actionneurs-liste{
        overflow-x: auto;
    }
}
@media (max-width: 767px) {
.btn-container{
    display: none;
}
.actionneurs-liste {
    display: flex; /* Ajout pour permettre le centrage */
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Centre les éléments horizontalement */
}
.item-actionneur {
    margin-top: 30px;
    width: 300px; /* Ajustez la largeur si nécessaire */
}

}

</style>